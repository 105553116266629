import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SeoComponent from "../components/seo"

const NotFoundPage = () => (
	<Layout>
		<SeoComponent title="404: Not found" />
		<div className="aux-page">
			<div className="container text-center">
				<h1 className="font-weight-bold text-white">
					This page is not available.
				</h1>
				<p className="text-white">
					Please check if the URL is entered correctly.
					<br />
					If you think this is an error, please contact us.
				</p>

				<Link to="/" className="btn btn-primary">
					Return Home
				</Link>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
